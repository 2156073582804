@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    font-family: 'IBM Plex Sans', sans-serif;
    scroll-behavior: smooth;
}

body {
    background: #f3f4f4;
}

.img-box-width {
    width: 40% !important;
}

.MuiTabs-flexContainer {
    height: 35px !important;
    align-items: center !important;
    background: #f3f4f4;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #000 !important;
    background-color: #e9ecef !important;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
    position: absolute !important;
    right: 0px !important;
    top: calc(50% - 18px) !important;
    background: white !important;
}

.MuiButtonBase-root {
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    padding: 8px 11px !important;
}

.css-38raov-MuiButtonBase-root-MuiChip-root {
    height: 22px !important;
}

    .css-38raov-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
        font-size: 18px !important;
    }

.modal {
    --bs-modal-width: 600px !important;
}

textarea {
    font-size: 13px;
}

.sc-iGgWBj {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-width: 100% !important;
    max-width: 508px !important;
    height: 30px !important;
    margin-right: 10px !important;
    margin-top: 0px !important;
    border: 1px solid #ced4da !important;
    padding: 10px 16px 10px 8px !important;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex-grow: 0;
}

    .sc-iGgWBj:focus {
        border: 1px solid #5a8dee !important;
    }

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #1976d2;
    border-width: 1px !important;
}

.sc-iGgWBj > svg {
    width: 25px !important;
    height: 25px !important;
}

.sc-kAyceB {
    margin-left: 5px !important;
}

.sc-kAyceB {
    margin-left: 5px !important;
}

    .sc-kAyceB > span {
        font-size: 11px !important;
        color: rgb(102, 102, 102);
    }

    .sc-kAyceB > span {
        font-size: 11px !important;
        color: rgb(102, 102, 102);
    }

.active-folder {
    background-color: #e5edfc !important;
}

.sub-menu .active {
    color: #2641a8 !important;
}

.sidebar .nav-links li .sub-menu .active::before {
    color: #2641a8 !important
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 5px 7px 7px !important;
    font-size: 12px !important;
}

.sidebar .sub-menu-search li .sub-menu-link {
    color: var(--text2);
    font-size: 13px;
    padding: 5px 0 5px 35px;
    white-space: nowrap;
    opacity: 1;
    transition: all 0.3s ease;
}

.sidebar .sub-menu-search {
    padding: 6px 0px 6px 0px;
    background: #ffffff;
    max-height: 50vh;
    overflow-x: hidden;
    margin: 60px 8px 8px 8px;
    overflow-Y: auto;
    border-radius: 5px 5px 5px 5px;
}

    .sidebar .sub-menu-search li a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .sidebar .sub-menu-search li {
        position: relative;
        list-style: none;
        transition: all 0.4s ease;
    }

    .sidebar .sub-menu-search::-webkit-scrollbar {
        width: 3px;
        height: 15px;
    }

    .sidebar .sub-menu-search::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .sidebar .sub-menu-search::-webkit-scrollbar-thumb {
        background: #5553;
        border-radius: 30px;
    }

    .sidebar .sub-menu-search li .sub-menu-link::before {
        content: "\f111";
        font-family: 'FontAwesome';
        height: 10px !important;
        width: 0.375rem !important;
        background:;
        font-size: 8px;
        position: absolute;
        color: #a8b1bb;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.5s;
    }

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 320px !important;
    padding: 25px !important;
    text-align: left;
    background: #f7e1e1 !important;
    border-radius: 10px !important;
    box-shadow: 0 20px 14px rgb(149 124 124 / 77%) !important;
    color: #666 !important;
}

.react-confirm-alert-button-group {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end !important;
    margin-top: 25px !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 5px 7px 7px !important;
    font-size: 12px !important;
}


:root {
    --first_theme: #5a8dee;
    --second_color: #5a8dee;
    --third_color: #e5edfc;
    --fourth_color:#02B5E6;
    --text: #516377;
    --text2: #414141;
}

.react-confirm-alert-overlay {
    background: none !important;
    z-index: 999 !important;
    align-items: flex-start !important;
}

.react-confirm-alert-button-group > button:last-child {
    background: red !important;
}

.dis-btn button {
    border-radius: 5px !important;
    width: fit-content !important;
    padding: 5px 10px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--text);
}

h5 {
    font-size: 1.125rem;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-top: 0;
    margin-bottom: 0rem;
    font-family: "Rubik","Times New Roman",serif;
    font-weight: 500;
    line-height: 1.1;
    color: var(--text);
}

p {
    color: var(--text);
}

.btn-primary {
    color: #fff;
    background-color: var(--fourth_color) !important;
    border-color: var(--fourth_color) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

    .btn-primary:hover {
        color: #fff !important;
        background-color: #4fd3f7 !important;
        border-color: #4fd3f7 !important;
        box-shadow: 0 0.25rem 1rem rgba(147,158,170,.45) !important;
    }

.btn-danger {
    color: #fff;
    background-color: #ff5b5c !important;
    border-color: #ff5b5c !important;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

    .btn-danger:hover {
        color: #fff !important;
        background-color: #ff7c7d !important;
        border-color: #ff7c7d !important;
        box-shadow: 0 0.25rem 1rem rgba(147,158,170,.45) !important;
    }

.btn-warning {
    color: #fff;
    background-color: #fdac41 !important;
    border-color: #fdac41 !important;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

    .btn-warning:hover {
        color: #fff !important;
        background-color: #fdbd67 !important;
        border-color: #fdbd67 !important;
        box-shadow: 0 0.25rem 1rem rgba(147,158,170,.45) !important;
    }

.btn-success {
    color: #fff;
    background-color: #39da8a !important;
    border-color: #39da8a !important;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

    .btn-success:hover {
        color: #fff !important;
        background-color: #61e1a1 !important;
        border-color: #61e1a1 !important;
        box-shadow: 0 0.25rem 1rem rgba(147,158,170,.45) !important;
    }

.light-bg {
    background-color: #fff;
}
.log_height{
    height:100vh;
}
.mybtn {
    background-color: var(--first_theme);
    padding: 8px 30px;
    color: #fff;
    border: 0;
    border-radius: 5px;
    transition: .3s ease;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

    .mybtn:hover {
        background-color: var(--second_color);
        color: #000;
    }

.login-page {
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
}

    .login-page .login-img {
        width: 100%;
        padding: 0 175px;
        position: relative;
        top: 45%;
        transform: translateY(-50%);
    }

.video_overlay {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    z-index: 999;
    position: absolute;
}

.login-bg h6 {
    font-size: 12px;
    font-weight: 500;
}

.login-trouble a {
    font-size: 13px !important;
}

.login-content {
  
    transform: translate(0, 20%);
    padding: 10px 65px;
    width: 100%;
    border-radius: 15px;
}



.login-content .form-control, .form-select {
    font-size: 13px !important;
}

.login-trouble a {
    font-size: 13px !important;
}

.login-content label {
    font-size: .75rem;
    text-transform: uppercase;
    letter-spacing: inherit;
    color: var(--text);
}

.MuiInputBase-input::placeholder {
    color: #000 !important;
}

.MuiInputBase-input {
    height: 15px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #fdac41 !important;
    background-color: #fdac414a;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

::placeholder {
    color: #c5c5c5 !important;
}

.login-content .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--second_color);
    outline: 0;
    box-shadow: unset;
}

.login-content .form-floating > label {
    transition: .3s ease-in-out !important;
}

.banner {
    background-image: url(./images/yellow-arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.sagar_logo img {
    margin: 0 auto;
}


/*top-menu*/
.top-menu .nav-item {
    margin: 0 0 0 5px;
}

.top-menu .nav-link {
    border-radius: 5px;
    color: #414141;
    padding: 3px 8px !important;
    font-size: 13px !important;
}

.green_dot {
    height: 8px;
    width: 8px;
    background-color: #01d201;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
}

.top-menu .nav-link.active {
    background-color: var(--third_color);
    color: var(--first_theme);
}

.top-menu .nav-link:hover {
    background-color: #f1f1f1;
    color: #000;
}
/*header*/
.navbar {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    z-index: 99 !important;
}

.fa-bars {
    font-size: 22px;
    cursor: pointer;
}

.top-logo {
    transition: .3s all;
}

.main-header .navbar-nav .dropdown-menu {
    position: absolute;
    width: 200px;
    border: 0;
    border-radius: 0;
    box-shadow: 0px 0px 5px 0px #cecece;
}

.dropdown-menu li a i {
    color: var(--first_theme);
}

.scroll-menu {
    max-width: 100%;
    min-width: 50%;
}

.horizontal-scroll {
    overflow-X: scroll;
    max-width: 100%;
}

    .horizontal-scroll::-webkit-scrollbar {
        display: none;
        scroll-behavior: smooth;
    }

.horizontal-left, .horizontal-right {
    position: relative;
    display: block;
    flex: 0 0 auto;
    cursor: pointer;
    padding: 0 10px;
}
/*.horizontal-scroll::-webkit-scrollbar{
    display:none;
}*/
/*side-bar*/
.sidebar {
    position: fixed;
    top: 70px;
    left: 0;
    height: 100%;
    box-shadow: 0 0 0 1px #d4d8dd;
    width: 260px;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 2;
    background-color: #f3f4f4;
    transition: all 0.5s ease;
}

    .sidebar.close {
        left: -265px;
    }

.brand {
    height: 67px;
    display: block;
    border-bottom: 1px solid var(--first_theme);
}

.sidebar .logo-details {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}

    .sidebar .logo-details i {
        font-size: 30px;
        color: #11101d;
        height: 50px;
        min-width: 78px;
        text-align: center;
        line-height: 50px;
    }

    .sidebar .logo-details .logo_name {
        font-size: 22px;
        color: #fff;
        font-weight: 600;
        transition: 0.3s ease;
        transition-delay: 0.1s;
    }


/* Media query for screens smaller than 768px (typical mobile screens) */
@media (max-width: 768px) {
    .log_height {
        height:100%; /* Remove margin for small screens */
    }
}
.sidebar.close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links {
    padding: 0px 0 150px 0;
}

.sidebar::-webkit-scrollbar {
    width: 3px;
}

.sidebar::-webkit-scrollbar-track {
    background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
    background: rgba(147,158,170,.45);
    border-radius: 30px;
}

.sidebar.close .nav-links {
    overflow: visible;
}

#sidebar-nav .menu_item {
    margin: 0 10px 5px;
    border-radius: 5px;
}

#sidebar-nav .link_name {
    color: var(--text2);
    font-size: 13px;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}


    .sidebar .nav-links li .iocn-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px 0 10px;
        cursor: pointer;
        border-radius: 5px;
    }

.sidebar.close .nav-links li .iocn-link {
    display: block
}

.sidebar .nav-links li i {
    text-align: center;
    width: 20px;
    line-height: 26px;
    color: var(--text2);
    margin-right: 5px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}


.sidebar .nav-links li .fa-chevron-right, .sidebar .nav-links li .fa-chevron-down {
    font-size: 10px;
    margin-right: 0px;
    transition: .3s ease-in-out;
}

.sidebar .nav-link:not(.collapsed) .fa-chevron-right {
    transform: rotate(90deg);
}

.sidebar.close .nav-links i.arrow {
    display: none;
}

.sidebar .nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

    .sidebar .nav-links li a .link_name {
        font-size: 14px;
        font-weight: 400;
        color: #11101d;
        transition: all 0.4s ease;
    }

.sidebar .nav-links li.active a .link_name,
.sidebar .nav-links li:hover a .link_name {
    color: var(--first_theme);
}

.sidebar .nav-link:not(.collapsed) .link_name, .sidebar .nav-link:not(.collapsed) i {
    color: var(--first_theme) !important;
}

.sidebar .nav-link:not(.collapsed) .iocn-link {
    background-color: var(--third_color);
    border-radius: 5px 5px 0 0;
}

.sidebar li:hover {
    background-color: #dcdcdc;
}

.sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
    padding: 6px 0px 6px 0px;
    background: #e4e4e4;
    max-height: 38vh;
    overflow-x: hidden;
    overflow-Y: auto;
    border-radius: 0 0 5px 5px;
}

    .sidebar .nav-links li .sub-menu::-webkit-scrollbar {
        width: 3px;
        height: 15px;
    }

    .sidebar .nav-links li .sub-menu::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .sidebar .nav-links li .sub-menu::-webkit-scrollbar-thumb {
        background: #5553;
        border-radius: 30px;
    }

.sidebar .nav-links li.showMenu .sub-menu {
    display: block;
}

.sidebar .nav-links li .sub-menu a {
    color: var(--text2);
    font-size: 13px;
    padding: 5px 0 5px 35px;
    white-space: nowrap;
    opacity: 1;
    transition: all 0.3s ease;
}

    .sidebar .nav-links li .sub-menu a:hover {
        opacity: 1;
    }

.sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
}

.sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details {
    background: none;
}

.sidebar.close .profile-details {
    width: 78px;
}

.sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
    padding: 10px;
}

.sidebar .nav-links li .sub-menu .sub-menu-link::before {
    content: "\f111";
    font-family: 'FontAwesome';
    height: 10px !important;
    width: 0.375rem !important;
    background:;
    font-size: 8px;
    position: absolute;
    color: #a8b1bb;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
}

.sidebar .profile-details .job {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    opacity: .5;
    white-space: nowrap;
}

.sidebar .profile-details i.bx {
    min-width: 50px;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
    display: none;
}

.sidebar .profile-details .job {
    font-size: 12px;
}

.sidebar .child-sub-item-link {
    color: #000;
}

    .sidebar .child-sub-item-link::before {
        content: "\2b" !important;
        height: 16px !important;
        width: 0.375rem !important;
        font-size: 22px !important;
        background: none !important;
        position: relative !important;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.5s;
    }

.sidebar .sub-nav-link:not(.collapsed) .child-sub-item-link::before {
    content: "\f068" !important;
    font-family: 'FontAwesome';
    color: var(--second_color);
    height: 14px !important;
    font-size: 18px !important;
}

.sidebar .sub-menu .sub-menu-list {
    padding: 0px 6px 0px 20px;
    background: #fff;
}

.sidebar .nav-links li .sub-menu .child-sub-item-link {
    color: #1d1b31;
    font-size: 13px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 1;
    transition: all 0.3s ease;
}

.search-bar {
    padding: 10px;
    position: fixed;
    background-color: #fff;
    width: 260px;
    z-index: 99;
}

    .search-bar i {
        position: absolute;
        top: 18px;
        left: 20px;
        color: blue;
        opacity: .5;
    }

    .search-bar input {
        padding-left: 30px !important;
        border-radius: 30px !important;
        box-shadow: 0px 1px 5px 0px #cdcdcd;
    }

.fa-circle-xmark {
    font-size: 29px;
    display: none;
    color: var(--second_color);
    position: absolute;
    right: -15px;
    top: 20px;
    background: #fff;
    border-radius: 50%;
}


/*dashboard*/
.dashboard .dash-icon1 {
    height: 55px;
    width: 55px;
    background: #2CBAFB;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}
.dashboard .dash-icon2 {
    height: 55px;
    width: 55px;
    background: #CAB9F0;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}
.dashboard .dash-icon3 {
    height: 55px;
    width: 55px;
    background: #FDB31B;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}
.dashboard .dash-icon4 {
    height: 55px;
    width: 55px;
    background: #C2EED0;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}
.dashboard .dash-icon5 {
    height: 55px;
    width: 55px;
    background: #7D57D0;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}

.map-progress .progress {
    height: 10px !important;
}

.india-map {
    max-height: 280px;
}

.map-progress .north-zone {
    top: 60px;
    left: 69px;
}

.map-progress .east-zone {
    top: 40%;
    right: 45px;
}

.map-progress .west-zone {
    top: 49%;
    left: 45px;
}

.map-progress .south-zone {
    top: 70%;
    left: 110px;
}

    .map-progress .north-zone::before, .map-progress .east-zone::before, .map-progress .west-zone::before, .map-progress .south-zone::before {
        content: '';
        width: 12px;
        height: 12px;
        display: inline-block;
        position: relative;
        top: 0px;
        margin-right: 5px;
        background: #3184fd;
        left: 0;
        border-radius: 50%;
    }

.dash-icon i {
    font-size: 22px;
    color: #5a8dee;
}
/*main -form*/
.main-dash {
    margin-top: 60px;
}

.main-card {
    background: #fff;
    margin-bottom: 15px;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0 2px 14px rgba(38,60,85,.16);
}

    .main-card .card-header:first-child {
        background-color: #fff;
        background-color: #fff;
        padding: 20px 15px;
        padding: 20px;
        padding: 10px 15px;
        border: 0;
        margin: 0 !important;
    }

    .main-card .card-body {
        padding-top: 0;
    }

    .main-card .card-title {
        font-size: 1.10rem;
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
    }

    .main-card label {
        color: #414141;
        font-size: 13px;
        margin-bottom: 3px !important;
    }

.form-control:focus, .form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--first_theme);
    outline: 0;
    box-shadow: unset !important;
}

.form-control, .form-select {
    font-size: 13px !important;
    color: #202020 !important;
    padding: 5px 10px !important;
}

.input_arrange {
    padding: 3px 10px !important;
}

.filters .form-select {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}

.main-card .form-check-input:checked {
    background-color: green !important;
    border-color: green !important;
}

.main-card .checkbox-warning .form-check-input:focus {
    border-color: var(--first_theme);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 167, 85, 0.25);
}

.main-card .form-check-input[type="checkbox"] {
    border-radius: 30px;
}

.check-box .form-check-input[type="checkbox"] {
    border-radius: 2px;
}

.mylegend {
    float: unset !important;
    width: unset !important;
    padding: unset !important;
    margin-bottom: unset !important;
    font-size: unset !important;
    line-height: unset !important;
}

.owner-deatils {
    margin: 10px 15px;
    width: 97% !important;
    border: none;
    box-shadow: 0px 0px 5px -2px #555555;
}

.myfieldset {
    min-width: unset !important;
    padding: 10px !important;
    margin: 10px 0 !important;
    border: 1px solid #555 !important;
}

.check-box .form-check-input {
    width: 15px;
    height: 15px !important;
    border-color: #e7e7e7;
    border-width: 2px;
    background-color: #f5f5f5;
}

    .check-box .form-check-input:checked {
        background-color: var(--first_theme) !important;
        border-color: var(--first_theme) !important;
    }

.main-card .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.form-check-input {
    border-width: 0.125rem;
    width: 18px;
    height: 18px !important;
    border-color: #e7e7e7;
    border-width: 2px;
    background-color: #f5f5f5;
}

.main-card .form-check-input:focus {
    border-color: none !important;
    outline: 0;
    box-shadow: unset !important;
}

.main-card table .btn-xs {
    padding: 0.1875rem;
    width: 20px;
    height: 21px;
    min-width: 20px;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
}

.table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 13px;
}

.table-responsive::-webkit-scrollbar-track {
    background: #c7c7c7;
    border-top: 5px solid white;
    border-bottom: 5px solid white;
}

.table-responsive::-webkit-scrollbar-thumb {
    background: var(--second_color);
    border-radius: 5px;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.TableTrhover:hover {
    background-color: rgb(254, 231, 127,0.4) !important;
}

.form-select {
    padding: 5px 30px 5px 10px !important;
}

.sharp.btn-xs i {
    font-size: 12px;
}

.collapsing {
    transition: .3s ease-in-out;
}

.main-card .table tr th {
    font-size: 12px;
    letter-spacing: 1px;
    padding: 10px 10px;
    text-transform: capitalize;
    font-weight: 700;
    color: var(--text);
    font-family: "Rubik","Times New Roman",serif;
}

    .main-card .table tr th .fa-angle-up, .main-card .table tr th .fa-angle-down {
        font-size: 12px;
        color: #c0c0c0;
        cursor: pointer;
    }

    .main-card .table tr th .fa-angle-down {
        margin-top: -3px;
    }

        .main-card .table tr th .fa-angle-up.active, .main-card .table tr th .fa-angle-down.active {
            color: #000;
        }

.main-card .table tbody .fa-regular {
    cursor: pointer;
}

/*.main-card .table tbody tr:nth-child(odd) {
    background-color: #e7e7e7;
}*/

.main-card .table tr td {
    font-size: 12px;
    padding: 5px 10px;
    color: var(--text2);
}

.btn-edit {
    background: green;
    color: #fff;
}

    .btn-edit:hover {
        background: #045c04;
        color: #fff;
    }

.main-card table {
    white-space: nowrap;
    overflow-x: scroll;
}

/*    .main-card table tr th:first-child {
        width: 10px;
    }
*/
.main-card .btn {
    font-size: 13px;
    min-width: 6.875rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 5px 10px;
    overflow: hidden;
}

.required {
    color: red;
    font-size: 14px !important;
}

.main-card .btn-icon-start {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    margin: -0.5rem -0.75rem -0.5rem -1.188rem;
    padding: 0.5rem 0.8rem 0.5rem;
    float: left;
}

.main-card .btn:hover i {
    color: #fff;
}

.main-card .filters .search {
    border-radius: 5px 0 0 5px;
    border-right: 0px !important;
}

.main-card .filters .search-btn {
    height: 34px;
    border-radius: 0 5px 5px 0;
    width: 35px;
    min-width: 35px;
    background-color: var(--first_theme);
    margin: 0 8px 0 0 !important;
    color: #fff;
}

.main-card .filters .advance-search-btn {
    height: 34px;
    border-radius: 5px;
    width: 35px;
    min-width: 35px;
    background-color: var(--second_color);
    margin: 0 8px 0 0 !important;
    color: #fff;
}

.branch-select {
    border: 0;
    font-size: 13px !important;
    padding: 0.375rem 2.25rem 0.375rem 0 !important;
}

.fa-location-dot {
    top: 5px;
    right: 8px;
    width: 20px;
}

.lr-tracking.show {
    opacity: 1;
    width: 100%;
    overflow: hidden;
    display: block;
    transition: all .3s;
}

.lr-tracking.hide {
    opacity: 0;
    width: 0%;
    display: none;
    overflow: hidden;
    transition: all .3s;
}

.modal-ovarlay {
    background-color: rgba(0,0,0,.6);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: .5s;
    width: 100%;
    z-index: 999;
}

.modal-header .btn-close {
    position: absolute;
    right: -5px;
    top: -8px;
    width: 12px;
    background-color: var(--second_color);
    height: 12px;
    opacity: .8;
}

.client-mails button {
    background-color: var(--third_color);
    font-size: 12px !important;
    color: var(--first_theme);
    padding: 3px !important;
    min-width: 92px !important;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

    .client-mails button:hover {
        background-color: var(--first_theme);
        font-size: 13px;
        color: var(--first_theme);
        padding: 5px;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
    }

.client-mails {
    background: #f8f8f8;
}

.client-mails-scroll {
    overflow-y: auto;
    max-height: 200px;
}

    .client-mails-scroll::-webkit-scrollbar {
        width: 3px;
        height: 15px;
    }

.btn:active {
    box-shadow: unset !important;
}

.client-mails-scroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.client-mails-scroll::-webkit-scrollbar-thumb {
    background: #5553;
    border-radius: 30px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: var(--first_theme) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

.nav-pills .nav-link {
    color: var(--text2) !important;
    font-size: 14px;
}


/*---------------------------------------------------------Tree menu css----------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------*/

.tree_menu {
    width: 280px;
    background: #fff;
    padding: 15px 5px;
    border-radius: 10px;
    box-shadow: 0 2px 14px rgba(38,60,85,.16);
}

    .tree_menu .tree_item {
        margin: 5px 0;
        position: relative;
        width: 250px;
        min-width: max-content;
        max-width: 100%;
        cursor: pointer;
    }

    .tree_menu li ul {
        position: relative;
        left: 0;
        padding-left: 18px;
    }

        .tree_menu li ul:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 3px;
            display: block;
            width: 0;
            border-left: 1px solid #a2a2a2;
            content: "";
        }

        .tree_menu li ul .tree_item:before {
            position: absolute;
            top: 50%;
            background: #adadad;
            bottom: 0;
            left: -14px;
            display: block;
            width: 10px;
            content: "";
            height: 1px;
        }

.tree_view ul {
    list-style: none;
}

.tree-link {
    font-size: 12px;
    max-width: 124px;
    white-space: nowrap;
    overflow: hidden;
}

.tree_view i {
    font-size: 13px;
    margin-left: 5px;
}

.oda_scroll {
    max-height: 22vh;
    overflow-y: scroll;
}

    .oda_scroll::-webkit-scrollbar {
        width: 5px;
    }

    .oda_scroll::-webkit-scrollbar-thumb {
        background-color: var(--third_color)
    }

@media only screen and (max-width: 768px) {
    .login-page .login-img {
        height: unset;
    }

    .icon {
        display: block !important;
    }

    .login-content {
        padding: 10px 20px;
    }

    .login-content {
        position: relative;
        transform: translate(0, 0%);
        padding: 20px 50px;
        width: 100%;
        border-radius: 15px;
    }

    .scroll-menu {
        display: none !important;
    }

    .login-page .login-img {
        width: 100%;
        position: relative;
        top: 45%;
        padding: 0;
        margin-bottom: 10px;
        transform: translateY(-50%);
    }

    .owner-deatils {
        margin: 10px 15px;
        width: 92% !important;
        box-shadow: 0px 0px 5px -2px #555555;
    }
}

.card {
    display: inline-block;
    width: 100%;
    position: relative;
    border-radius: 10px;
}

.card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

    .card-1:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }



.comp_card {
    position: relative;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    transition: all 0.5s;
    height: 179px;
}

    .comp_card img {
        transition: all 0.5s;
        height: 179px;
    }

    .comp_card:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 80px;
        background: var(--second_color);
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        bottom: 0px;
        left: 0;
        z-index: 9;
    }

    .comp_card:after {
        content: "";
        position: absolute;
        width: 27px;
        height: 80px;
        background: var(--fisrt_theme);
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        top: 0px;
        right: 0;
        z-index: 9;
    }

.info {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    padding: 40px;
}

.comp_card h1 {
    font-size: 18px;
    background: var(--fisrt_theme);
    line-height: 23px;
    color: #fff;
    max-width: fit-content;
    padding: 3px 5px;
    border-left: 3px solid yellow;
}

.comp_card p {
    font-size: 16px;
    line-height: 27px;
    color: #000000a8;
}

.comp_card:hover img {
    transform: scale(1.06);
}

.descipt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 40px;
    background: linear-gradient(355deg, rgb(2 0 36) 0%, rgb(183 54 54 / 64%) 35%, rgb(0 212 255 / 0%) 100%);
    display: flex;
    align-items: flex-end;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 5px 7px 7px !important;
    font-size: 12px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 6px 7px 6px !important;
    font-size: 12px !important;
}

.MuiAutocomplete-listbox {
    font-size: 10px !important;
}

div.MuiAutocomplete-root {
    margin-bottom: 0 !important;
}

.MuiAutocomplete-popper {
    z-index: 10 !important;
}

.MuiOutlinedInput-root {
    padding: 0px !important;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    width: 100% !important;
    border: 0;
}



.date-card {
    display: inline-block;
    padding: 30px 0px 0px 0px;
    position: relative;
    width: 100%;
    border-radius: 10px;
    text-align: center;
}

    .date-card h1 {
        font-size: 22px;
        line-height: 30px;
        color: #000000a8;
    }

    .date-card input {
        width: 240px;
        font-size: 16px;
        line-height: 27px;
        color: #000000a8;
        margin: 0 auto;
    }

.login-image {
    width: 100% !important;
    height: 100vh !important
}

@media only screen and (min-width: 200px) and (max-width: 768px) {
    .img-box-width {
        width: 100% !important;
    }

    .login-image{
        width: 100% !important;
        height: 300px !important
    }

    .header-logo {
        width: 100px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1920px) {
    .marg-left {
        margin-left: 260px;
        transition: all 0.5s ease;
    }

    .main-header, .main-dash {
        transition: all 0.5s ease;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .scroll-menu {
        max-width: 40%;
        min-width: 35%;
    }

    .login-page .login-img {
        width: 100%;
        position: relative;
        top: 45%;
        transform: translateY(-50%);
        padding: 0 60px;
    }

    .login-content {
        transform: translate(0, 0%);
    }

    .login-page .login-img {
        height: unset;
    }

    .sidebar-ovarlay {
        background-color: rgba(0,0,0,.6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        transition: .5s;
        width: 100%;
        z-index: 1;
    }

    .sidebar {
        top: 55px;
    }

    .top-menu .nav-link {
        padding: 5px 10px !important;
        font-size: 12px !important;
    }

    .collapse-width {
        max-width: 49% !important;
        min-width: 47% !important;
    }

    .sampark-logo {
        width: 140px;
    }

    .icon {
        width: 42px;
    }

    .nav-side-icon .nav-link {
        padding: 3px !important;
    }

    .navbar .form-control, .form-select {
        font-size: 10px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .nav-side-icon .nav-link {
        padding: 3px !important;
    }

    .login-page .login-img {
        width: 100%;
        position: relative;
        padding: 0px 95px;
        top: 45%;
        transform: translateY(-50%);
    }

    .login-content {
        transform: translate(0, 0%);
    }

    .top-menu .nav-link {
        padding: 5px 10px !important;
        font-size: 12px !important;
    }

    .navbar .form-control, .form-select {
        font-size: 10px !important;
    }

    .sampark-logo {
        width: 140px;
    }

    .scroll-menu {
        max-width: 100%;
        min-width: 50%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .login-page .login-img {
        width: 100%;
        position: relative;
        top: 45%;
        transform: translateY(-50%);
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1920px) {
    .cntrl-width {
        width: 20%;
    }
}


.main-div {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.main-div-blur {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.4);
}

.main-div img {
    position: absolute;
    z-index: 1;
    top: 31px;
    width: 72px;
    left: 25px;
}

.main-div-blur img {
    position: absolute;
    z-index: 1;
    top: 34px;
    width: 72px;
    left: 51px;
}

.loader {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    z-index: 5;
    border-color: var(--second_color) var(--second_color) transparent transparent;
    -webkit-animation: loader1 1s linear infinite;
    animation: loader1 2s linear infinite;
}

.loader:after, .loader:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent #FF3D00 #FF3D00;
        width: 105px;
        height: 105px;
        border-radius: 50%;
        -webkit-animation: loader2 0.5s linear infinite;
        animation: loader2 1s linear infinite;
        transform-origin: center center;
    }

.inActive {
    border: 1px solid #ddd2d2 !important;
}

.loader:before {
    width: 95px;
    height: 95px;
    border-color: var(--second_color) var(--second_color) transparent transparent;
    -webkit-animation: rotation 1.5s linear infinite;
    animation: loader1 2.5s linear infinite;
}

@keyframes loader1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@media only screen and (max-width:500px) {
    .main-card .btn {
        min-width: 3.875rem;
    }

}


.forgot-icon {
    position: absolute;
    top: 50%;
    right: 05%;
    transform: translate(8px, 2px);
}

.forgot-icon i {
        font-size: 13px;
    }



.error-msg{
    font-size:10px;
    color:tomato;
}

.number_align{
    text-align:right
}

.guZdik {
    min-width: 171px !important;
    height: 29px !important;
    overflow:hidden !important;
}

.jWkLDY > span {
    font-size: 7px !important;
    color: rgb(102, 102, 102);
}
.details-tableAwb {
    height: 60vh;
    overflow-y: scroll;
}
.details-table {
    height:20vh;
    overflow-y:scroll;
}

.movement-time-picker input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

.table_heading {
    background: linear-gradient(135deg, #485296 0%, #8F3481 100%);
    box-shadow: 0 5px 30px 0 rgba(214 215 216 / 57%);
    /* background-color: #223F6C;*/
    padding: 8px;
    color: #fff !important;
    font-weight: 700 !important;
}

.show-remarks{
    color:blue
}

.show-remarks:hover {
    color: blue;
    cursor:pointer;
    border-bottom:1px solid blue;
}

.header-logo{
    width:140px;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .header-logo {
        width: 100px;
    }

    .sidebar {
        top: 55px;
    } 
}

@media only screen and (min-width: 300px) and (max-width: 600px) {

    .Toastify__toast {
        width: 70% !important;
        margin: auto !important;
        top: 10px !important;
        min-height: 45px !important;
        font-size: 14px !important;
    }
}
/*@media (min-width: 992px) {
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}*/